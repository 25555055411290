@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes dash {
  to {
    background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
  }
}

@layer components {
  .container {
    @apply max-w-[1280px] w-full mx-auto my-0 px-[1.5em] md:px-[2em] py-0;
  }

  /* .common-btn {
    @apply text-[1.125em] font-normal leading-[1.25em] text-[#ffffff] bg-[#DA3654] inline-block cursor-pointer px-[1.5em] py-[1em] rounded-[3.5em] hover: text-[#ffffff] hover:bg-[#222549] hover:shadow-[0_0.1875em_0.75em_#4a3aff2e];
  } */

  .section-header {
    @apply max-w-[50.5625em] mb-[1.5em];
  }
}

@layer utilities {
  .contentList::-webkit-scrollbar {
    width: 10px;
  }

  .contentList::-webkit-scrollbar-track {
    background: transparent;
    border: 1px solid #fff;
    border-radius: 10px;
  }

  .contentList::-webkit-scrollbar-thumb {
    background: #222549;
    border-radius: 5px;
  }

  .toools {
    transition: all 0.2s ease-out;
  }
}

@import "./config/_variables.css";

/* // customer-review-slider */

.react-multi-carousel-list {
  padding-bottom: 4em;
}
.review-rating .star4 {
  background-image: url(../../web/public/five-star.png);
  background-size: contain;
  background-repeat: no-repeat;
}
.review-rating .star5 {
  background-image: url(../../web/public/five-star.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.react-multi-carousel-dot-list li {
  margin: 0 0.1em;
  padding: 0;
}
.react-multi-carousel-dot button {
  border: none !important;
  background-color: #da3654 !important;
  opacity: 0.5 !important;
}
.react-multi-carousel-dot--active button {
  opacity: 1 !important;
}

body.bg-drop {
  position: relative;
  overflow: hidden;
}

body.bg-drop:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  backdrop-filter: blur(10px);
}

@media (min-width: 1024px) {
  body.bg-drop:before {
    content: none !important;
  }
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

body {
  font-size: 1em;
  color: #000;
  background-color: #fff;
  position: relative;
  font-display: swap;
}

@media (max-width: 1440px) {
  body {
    font-size: 1em;
  }
}

@media (max-width: 1280px) {
  body {
    font-size: 0.875em;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
  color: #000;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.17em;
}

h4 {
  font-size: 1em;
}

h5 {
  font-size: 0.83em;
}

h6 {
  font-size: 0.75em;
}

p {
  color: #000;
  margin-bottom: 0;
  padding-bottom: 1em;
  line-height: 1.5em;
  font-weight: 400;
  font-size: 1.125em;
}

a {
  text-decoration: none;
  outline: none;
  color: #000;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  font-size: 1em;
  font-weight: 400;
  display: inline-block;
  cursor: pointer;
}

a:hover {
  color: #000ee6;
  text-decoration: none;
}

img {
  vertical-align: top;
  max-width: 100%;
  height: 100%;
  width: 100%;
  border: none;
}

p,
ul,
ol,
pre,
table,
blockquote {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  list-style-type: none;
}

ul ul,
ol ol,
ul ol,
ol ul {
  margin-top: 0;
  margin-bottom: 0;
}

input,
select,
textarea,
button {
  font-size: 1em;
  color: #000;
  font-weight: 400;
  outline: none;
}

iframe {
  max-width: 100%;
  border: none;
}

input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  /* // border: none; */
  background-color: rgba(0, 0, 0, 0);
  padding: 0 1em;
  height: 3em;
  max-width: 100%;
  border-radius: 0;
  box-shadow: none;
  width: 100%;
  color: #000;
  font-size: 1em;
  outline: none;
  font-weight: 400;
  line-height: normal;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  background: rgba(0, 0, 0, 0);
}

select {
  border: none;
  background: #fff;
  padding: 0 1em;
  height: 3em;
  box-shadow: none;
  font-size: 1em;
}

/* input[type="image"], */
input[type="button"],
button {
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
  border: none;
  border-radius: 0;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  padding: 0.5em 1em;
  line-height: normal;
  transition: all 0.3s linear;
  -webkit-transition: all 0.15s linear;
  font-weight: 400;
  text-transform: inherit;
  appearance: normal;
  -webkit-appearance: none;
  outline: none;
  text-align: center;
  position: relative;
}

textarea {
  resize: none;
  min-height: 8.84em;
  padding: 0.8em 1.563em;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

::placeholder {
  opacity: 1;
  font-size: 1em;
  color: #000;
  font-weight: 400;
}

:-ms-input-placeholder {
  opacity: 1;
  font-size: 1em;
  color: #000;
  font-weight: 400;
}

::-ms-input-placeholder {
  opacity: 1;
  font-size: 1em;
  color: #000;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize !important;
}

/* // error-massage */
.error {
  border-color: red !important;
}
.slick-dots {
  display: flex !important;
  justify-content: center;
}
.slick-track {
  display: flex !important;
}

#nprogress .bar {
  background: linear-gradient(
    111.02deg,
    #222549 24.12%,
    #da3654 101.68%
  ) !important;
  height: 0.2em !important;
}
#nprogress .spinner {
  display: none !important;
}

.popupOpenImage {
  overflow-y: hidden;
}

.gs li {
  margin-left: 0px !important;
}

@media (max-width: 640px) {
  .accordion__button {
    cursor: default;
  }
}

.offer-pop-open .offer-pop-form {
  bottom: 0;
  transition: all 0.6s;
}

.offer-pop-close .offer-pop-form {
  bottom: -30em;
  transition: all 0.6s;
}

.offer-pop-close .offer-btn {
  z-index: 0;
}

.bg-drop .offer-btn {
  z-index: 0;
}
.bg-drop .offer-pop-form {
  z-index: 0;
}

/* home page slider css */


/* @media (min-width: 2500px) {
  .carousel-track {
     width: 140%; 
  }
} */

@keyframes slide {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}


.marqueeText {
	width: 100%;
	display: flex;
	overflow: hidden;
}

.marqueeTextInner {
	flex: 0 0 auto;
	animation: scroll 50s linear infinite;
	animation-play-state: running;
}
@keyframes scroll {
	0% {
		transform: translateX(0%);
	}

	100% {
		transform: translateX(-100%);
	}
}


